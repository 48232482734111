import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'GtrYesNoInput'
})
export default class GtrYesNoInput extends Vue {
  @Prop({ required: true })
  value?: any;

  @Prop({ required: true, type: String })
  name?: string;

  @Prop({ required: true, type: Boolean, default: true })
  required?: boolean;

  options = [
    { value: 0, text: 'No' },
    { value: 1, text: 'Yes' }
  ];

  onChange (value: number) {
    this.$emit('input', value)
  }
}

import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrYesNoInput from '@/modules/common/components/ui-core/gtr-yesno-input/gtr-yesno-input.vue'
import { ContentPage, IChangelog } from 'gtr-types'
import { ValidationObserver } from 'vee-validate'

// TODO: Further refacor this page and edit pages to combine like functionality.
@Component({
  name: 'GtrRegistrationModuleNewContentPageView',
  computed: {
    ...mapState('contentpages', ['contentpages', 'currentlyDeployedLiveUUID', 'currentDevUUID'])
  },
  components: {
    changelog: ChangeLog,
    'on-navbar-input': GtrYesNoInput
  }
})
export default class GtrRegistrationModuleNewContentPageView extends GtrSuper {
  @Ref()
  readonly observerForm!: InstanceType<typeof ValidationObserver>

  @Ref()
  readonly form!: InstanceType<typeof HTMLFormElement>

  // VUEX BINDINGS.
  currentDevUUID!: Record<string, any>; // TODO: rename to have more semantic name.
  contentpages!: Record<string, any>;

  // COMPONENT STATE.
  // switched to local state to prevent changelogs from other pages appearing.
  changelog: IChangelog | null = null;
  event_uuid = this.$route.params.event_uuid;
  afterSave = false;
  contentPage: ContentPage = {
    languageToUse: 'en',
    name: null,
    navbar_name: null,
    show_in_navbar: null,
    display_order: null,
    body: {}
  };

  pageVersion: string | null = null;
  submitting = false;

  async mounted () {
    try {
      this.disablePaste('name')
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid: this.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get dev_uuid () {
    return this.currentDevUUID.dev?.uuid ?? null
  }

  get live_uuid () {
    return this.currentDevUUID.live?.uuid ?? null
  }

  @Watch('pageVersion')
  async onPageVersionChange (revision_uuid: string) {
    try {
      if (revision_uuid !== 'default') {
        // fetch the data for a given revisions, update the store.
        await this.$store.dispatch('contentpages/getContentpageByRevisionUuid', {
          event_uuid: this.event_uuid,
          revision_uuid
        })
      }
    } catch (error) {
      Container.get(Notification).error('there was a problem fetching the content page data.')
    }
  }

  @Watch('currentDevUUID')
  onCurrentDevUUIDChange (payload: any) {
    if (payload && payload.dev) {
      const { dev } = payload
      this.contentPage.name = dev.page_data.name
      this.contentPage.navbar_name = dev.page_data.navbar_name
      this.contentPage.body = dev.page_data.body
      this.contentPage.display_order = dev.page_data.display_order
      this.contentPage.show_in_navbar = dev.page_data.show_in_navbar
    }
  }

  async handleDeployed () {
    await this.$store.dispatch('contentpages/getCurrentlyDeployedLiveUUID', {
      type: 'page',
      sub_type: this.contentPage.name,
      event_uuid: this.event_uuid
    })
  }

  prefillNavbarName (val: string) {
    if (val) {
      this.$data.contentPage.navbar_name = val.replace(/[^\w]/gi, '_')
    }
  }

  contentpage_name () {
    if (this.contentPage.name) {
      this.contentPage.name = this.contentPage.name.replace(/[^\w]/gi, '_')
    }
  }

  updatePageVersion (uuid: string) {
    this.pageVersion = uuid
  }

  // checks if the name of page is in use.
  private checkContentPageExists (): void {
    if (!this.afterSave && this.contentPage.name &&
        (this.contentPage.name.toLowerCase() in this.contentpages)) {
      throw new Error('Content page name already in use.')
    }
  }

  // handle creating or saving new content.
  private async createOrSaveNewContent (): Promise<void> {
    const payload: any = {
      event_uuid: this.event_uuid,
      data: {
        ...this.contentPage,
        name: this.contentPage.name,
        body: this.contentPage.body,
        navbar_name: this.contentPage.navbar_name,
        show_in_navbar: this.contentPage.show_in_navbar,
        display_order: this.contentPage.display_order
      }
    }
    // After save, switch to edit call to prevent error getting thrown.
    if (!this.afterSave) {
      await this.$store.dispatch('contentpages/createContentPage', payload)
    } else {
      payload.name = this.contentPage.name?.toLowerCase() // add page name for edit call.
      await this.$store.dispatch('contentpages/editContentPage', payload)
    }
    this.fetchChangelog()
    Container.get(Notification).success('Content page successfully created.')
  }

  // handle thrown errors.
  private handleErrors (error): void {
    switch ((error as Error).message) {
      case 'Content page name already in use.':
        Container.get(Notification).error((error as Error).message)
        break
      default:
        Container.get(ErrorHandlerService).error(error)
    }
  }

  // fetch the changelog, and store it locally.
  async fetchChangelog () {
    const { data } = await this.$store.dispatch('contentpages/getChangelog', {
      type: 'page',
      sub_type: this.contentPage.name,
      event_uuid: this.event_uuid
    })
    this.changelog = data
  }

  // TODO: refactor submit if possible. it's getting a little meaty.
  async submit () {
    if (!(await this.observerForm.validate()) && !(this.form.validat())) return
    try {
      // if the page has not been saved, the name is present, check if exists
      this.submitting = true
      this.checkContentPageExists()
      await this.createOrSaveNewContent()
      this.afterSave = true
    } catch (error) {
      this.handleErrors(error)
    } finally {
      this.submitting = false
    }
  }
}
